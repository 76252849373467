



import Vue from "vue";
import { mapState } from "vuex";
import { getSafeRedirectUrl } from "./MigrationRedirect";
export default Vue.extend({
  name: "migrate-view",
  async mounted() {
    if (!this.redirectURI) return await this.$router.push("/faq");

    try {
      const redirectURI = getSafeRedirectUrl(this.redirectURI);
      window.location.href = `${window.location.origin}${redirectURI}`;
      return;
    } catch {
      await this.$router.push("/faq");
    }
  },
  metaInfo() {
    return {
      title: "Funimation",
    };
  },
  computed: {
    ...mapState({
      redirectURI: (state: any) => state.route.query.redirect_uri,
    }),
  },
});
